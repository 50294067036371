import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorLauren as author } from 'data/authors';
import img from 'img/blog/covers/increase-urgency.png';
import img1 from 'img/blog/increase-urgency/limited-time-sale.png';
import Banner from 'components/usability-testing/banner';

const Content = () => {
  return (
    <Post>
      <p>
        {' '}
        The “abandoned cart” is an issue for many online businesses. For those who don’t know, the
        abandoned cart occurs when a potential customer goes through the process of logging onto a
        business’s website, choosing a product, adding it to their purchase basket, but doesn’t end
        up finishing the checkout purchase.{' '}
      </p>

      <p>
        This can be particularly frustrating for businesses as it means at least part of their
        marketing campaign is working, and many don’t know how to fix their abandoned cart issues.{' '}
      </p>

      <p>
        Over the years, many marketers and business owners have noticed that the best way to deal
        with their abandoned cart problems is by creating a sense of urgency. Over the remainder of
        this article, we will go over some effective ways online shopping platforms can increase
        urgency.
      </p>
      <h2>1. Offer Quick Shipping Options</h2>
      <p>
        One thing that researchers found was that long shipping periods are likely to have customers
        clicking off or thinking twice about making a purchase. “Choosing shipping and payment
        options is usually the last step in the buying process. When a potential customer gets to
        that step and sees they are going to have to wait 10 - 15 business days for shipping, many
        begin to think twice about their purchase”, writes Jame Keen, a conversion expert at{' '}
        <a href="https://liahelp.com/" target="_blank" rel="noopener noreferrer nofollow">
          Liahelp
        </a>{' '}
        and{' '}
        <a href="https://bigassignments.com/" target="_blank" rel="noopener noreferrer nofollow">
          Bigassignments
        </a>
        .
      </p>

      <p>
        Although not always possible depending on the product or location, one of the best ways to
        combat this is by offering fast shipping options. An even more effective method would be to
        offer free fast shipping over a certain price. An example of this and how successful it can
        be is Amazon. Now of course, not every business is able to offer the type of free shipping
        that Amazon does, but it is a good case study.
      </p>

      <p>
        Depending on the country or region, Amazon offers free three-day shipping for all purchases
        over a certain amount. Not only does this prompt users to checkout, but in many cases it
        also makes them look for another item to add to their cart if they find themselves under the
        free shipping amount.
      </p>

      <img
        src={img1}
        alt="Limited time sale"
        title="Offer Quick Shipping Options"
        style={{ margin: '3rem auto 1rem' }}
      />

      <h2>2. Remind Customers Of A Limited Time Sale</h2>
      <p>
        The appearance of scarcity is another great way to stop customers from having second
        thoughts. Marketers and psychologists have found that consumers are much more likely to
        purchase something if they think they are getting a limited-time deal, especially if it is
        close to expiring. In this case, the buyer doesn’t want to feel like they have missed out
        and end up going through with their purchase before they have any time for second doubts.
      </p>

      <p>
        One important aspect to consider when doing this is the placing of the sale reminder. Many
        sites are successful in making the customer aware that there is a limited-time sale going on
        but they don’t remind them once they are actually at the checkout phase. This is a major
        mistake and one that highly reduces the effectiveness of this technique.
      </p>

      <p>
        When running sales, it is important to inform the customer that the sale is on for a limited
        time at every step of the checkout process.
      </p>
      <h2>3. Show Stock Scarcity</h2>
      <p>
        Another way businesses can take advantage of scarcity is by displaying low stock levels on
        their website. An industry that is known for doing this is the airline ticket sales
        industry, which will often have “only two tickets left at this price” at the top of the most
        desired search results. “When dealing with scarcity it is important to operate with some
        ethics. Many business owners feel it is bad practice to lie outright, and most people would
        agree with this. However, when a certain item&apos;s stock does become low, there is nothing
        wrong with informing would-be buyers of this fact”, writes Susan Veril, a tech writer at{' '}
        <a
          href="https://eliteassignmenthelp.com/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Eliteassignmenthelp
        </a>{' '}
        and{' '}
        <a
          href="https://essayroo.com/nursing-essay"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Essayroo
        </a>
        .
      </p>

      <p>
        One thing to keep in mind is if a website lists products as always low in stock, repeat
        customers, or those thinking about making another purchase, will see right through this ploy
        and may make people hesitant to make another purchase. Because of this, it makes good
        business sense to be honest and only say a product is low in stock when it is.
      </p>
      <h2>4. Offer A Checkout Discount</h2>
      <p>
        {' '}
        Another excellent tactic to increase urgency is by offering a small discount upon or close
        to check out. Typically, this works by having a pop-up or banner come across the screen
        advertising a further discount for those who act fast. Most of the time, the discount offers
        a further 5 - 10 percent off the current price but only for a limited amount of time.
      </p>

      <p>
        This method has proven to be quick effective, especially when combined with fast shipping
        options. How much of a discount to give will depend on the business and product, but it
        doesn’t have to be much. Some studies have shown that a limited time discount of even just
        5% is enough to persuade many would-be buyers who might have second thoughts about
        completing their purchase.
      </p>
      <Banner
        title="Recover more abandoned carts with session replays"
        desc="Improve your customer’s path to purchase using qualitative data."
      />
      <h2>5. Let Customers Know Other People Are Shopping For The Product</h2>
      <p>
        {' '}
        Sometimes scarcity can be pushed by letting a potential buyer know that other people are
        interested in the same product, especially if that product has limited stock. Using the
        airline ticket industry as an example again, when searching for tickets, these booking
        agencies will usually inform people how many other people are searching for the same ticket
        with the same dates.{' '}
      </p>

      <p>
        When combined with limited stock or a minor sale, this tactic can be very effective as it
        creates a sense of urgency by letting the individual know there are other people interested
        and if they don’t move quickly they may lose their opportunity.
      </p>
      <h2>6. Offer Cross-Sells </h2>
      <p>
        A cross-sell is when another related item is bundled with the current item and the customer
        is offered a slight discount. Amazon is really good at this. For example, if a customer is
        searching for a book in particular series, right before checkout Amazon will offer them a
        bundle of another book in the same series for slightly less than what it would cost to buy
        the two individually.{' '}
      </p>

      <p>
        When this is done, it gets the individual thinking about whether the deal is worth it or not
        and takes them away from having second doubts about their purchase. Even if the customer
        doesn’t decide on purchasing the bundle, they may still feel like they have been responsible
        and saved some money by not making the larger purchase, a belief which ends up justifying
        their initial purchase even more.
      </p>
      <h2>7. Have Flash Sales</h2>
      <p>
        {' '}
        Flash sales are quick, seemingly random, sales that take a decent chunk off of a
        product&apos;s price. These types of sales may not be realistic for all types of products
        but can work very well in high-volume businesses. A great example of this is Shein, a large
        clothing retailer out of China. Anyone who has gone to their site knows that they have flash
        sales all the time, which can cut an extra 15-35% off the price of an item.
      </p>

      <p>
        It should be understood that flash sales are not random, and are usually programmed to occur
        with stock the business is trying to get rid of. The trigger for a flash sale is the amount
        of time spent on the website with items in their checkout basket. If the individual has
        spent a significant amount of time on the site without checking out, they are a good target
        for a flash sale.
      </p>
      <h2>8. Offer Presale For New Products </h2>
      <p>
        {' '}
        The idea of scarcity, and the urgency it rates, can be tapped into by offering a presale on
        a new product for which there will be a limited initial supply.
      </p>

      <p>
        This only works for products that are in high demand and a good example of this are
        Playstation or Xbox consoles. Every few years, a new iteration in the line of video game
        consoles is released. The initial release supply is limited and customers are able to
        pre-order their products, often at a slight increase in price. This works because the
        products are so heavily sought people are willing to pay a little extra upfront to have it
        when it&apos;s released.
      </p>
      <h2>9. Offer A Sign-Up Bonus With A Deadline</h2>
      <p>
        Mailing lists are very useful for marketing purposes, it allows a business to stay in
        communication for customers and inform them of new products and promotions. A great way to
        make use of this mailing list is by offering a time-sensitive sign-up bonus. For example, an
        offer can read “Sign up for our mailing list and receive 10% off your first purchase valid
        for 24 hours”.
      </p>

      <p>
        These types of deals are really enticing for people because not only does it create a sense
        of urgency, but it also positively reinforces their desire to make a purchase.
      </p>
      <h2>10. Display Countdown Timer For Sales</h2>
      <p>
        When there is a sale, displaying a countdown timer at the top of the screen is a good way to
        instill a sense of urgency in customers. This works particularly well for flash sales.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Abandoned Carts - Recover Them Creating A Sense Of Urgency',
  url: '/blog/10-ways-to-increase-urgency-in-your-online-sales/',
  description: `Over the years, many marketers and business owners have noticed that the best way to deal
  with their abandoned carts problems is by creating a sense of urgency.`,
  author,
  img,
  imgSocial: img,
  date: '2021-03-01',
  category: '',
  group: 'Research',
  timeToRead: 8,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
